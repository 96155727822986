exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-us-js": () => import("./../../../src/pages/about-us.js" /* webpackChunkName: "component---src-pages-about-us-js" */),
  "component---src-pages-blog-js": () => import("./../../../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-business-bank-account-therapy-js": () => import("./../../../src/pages/business/bank-account-therapy.js" /* webpackChunkName: "component---src-pages-business-bank-account-therapy-js" */),
  "component---src-pages-business-business-name-consulting-js": () => import("./../../../src/pages/business/business-name-consulting.js" /* webpackChunkName: "component---src-pages-business-business-name-consulting-js" */),
  "component---src-pages-business-name-therapy-for-business-js": () => import("./../../../src/pages/business/name-therapy-for-business.js" /* webpackChunkName: "component---src-pages-business-name-therapy-for-business-js" */),
  "component---src-pages-business-pin-code-matters-js": () => import("./../../../src/pages/business/pin-code-matters.js" /* webpackChunkName: "component---src-pages-business-pin-code-matters-js" */),
  "component---src-pages-contact-us-js": () => import("./../../../src/pages/contact-us.js" /* webpackChunkName: "component---src-pages-contact-us-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-name-consultation-js": () => import("./../../../src/pages/name-consultation.js" /* webpackChunkName: "component---src-pages-name-consultation-js" */),
  "component---src-pages-page-2-js": () => import("./../../../src/pages/page-2.js" /* webpackChunkName: "component---src-pages-page-2-js" */),
  "component---src-pages-personal-baby-name-consultation-js": () => import("./../../../src/pages/personal/baby-name-consultation.js" /* webpackChunkName: "component---src-pages-personal-baby-name-consultation-js" */),
  "component---src-pages-personal-bank-account-therapy-js": () => import("./../../../src/pages/personal/bank-account-therapy.js" /* webpackChunkName: "component---src-pages-personal-bank-account-therapy-js" */),
  "component---src-pages-personal-car-therapy-js": () => import("./../../../src/pages/personal/car-therapy.js" /* webpackChunkName: "component---src-pages-personal-car-therapy-js" */),
  "component---src-pages-personal-gemstone-therapy-js": () => import("./../../../src/pages/personal/gemstone-therapy.js" /* webpackChunkName: "component---src-pages-personal-gemstone-therapy-js" */),
  "component---src-pages-personal-gemstone-vials-js": () => import("./../../../src/pages/personal/gemstone-vials.js" /* webpackChunkName: "component---src-pages-personal-gemstone-vials-js" */),
  "component---src-pages-personal-home-therapy-js": () => import("./../../../src/pages/personal/home-therapy.js" /* webpackChunkName: "component---src-pages-personal-home-therapy-js" */),
  "component---src-pages-personal-name-matters-training-program-js": () => import("./../../../src/pages/personal/name-matters-training-program.js" /* webpackChunkName: "component---src-pages-personal-name-matters-training-program-js" */),
  "component---src-pages-personal-name-therapy-js": () => import("./../../../src/pages/personal/name-therapy.js" /* webpackChunkName: "component---src-pages-personal-name-therapy-js" */),
  "component---src-pages-using-ssr-js": () => import("./../../../src/pages/using-ssr.js" /* webpackChunkName: "component---src-pages-using-ssr-js" */),
  "component---src-pages-using-typescript-tsx": () => import("./../../../src/pages/using-typescript.tsx" /* webpackChunkName: "component---src-pages-using-typescript-tsx" */),
  "component---src-templates-using-dsg-js": () => import("./../../../src/templates/using-dsg.js" /* webpackChunkName: "component---src-templates-using-dsg-js" */)
}

